import { incrementCounter } from '../actions/incrementCounter';

const validateIos = () => {
    const ua = navigator.userAgent
    let check = false;
    if (ua.match(/iPad/i) || ua.match(/iPhone/i)) {
        check = true;
    }

    if ((/iPad|iPhone|iPod/.test(ua))) {
        check = true;
    }

    return check;
}

export const handleClick = ( url:string, zone:string, link?:string, whatsapp?:boolean, whatsappMessage?:string, whatsappNumber?:string,  ) => {
    if( link ) {

        const windowReference = window.open();
        incrementCounter( `zones/incrementVisits/${ zone }?link=${ link }` ).then(() => {
            if(whatsapp && whatsappMessage && whatsappNumber) {
                url = whatsappMessage.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                url = `api.whatsapp.com/send/?phone=${whatsappNumber}&text=` + url.replace(/\s/g, '+');
            }else{
                url = url.replace(/^https?\:\/\//i, '');
            }

            if( validateIos() ) {
                windowReference.location = `https://${ url }`;
            } else {
                const isWebView = navigator.userAgent.includes ('wv')
                if( isWebView ) {
                    window.open( `https://${ url }`, '_self');
                }else {
                    console.log('opening this')
                    windowReference.location = `https://${ url }`;
                }
            }
        });
    }
}