import * as React from 'react';
import { Button, CardActionArea, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import { handleClick } from '../helpers/handleClick';
import { truncateString } from '../helpers/truncateString';
import { useTheme } from '@mui/material/styles';

const CardComponent = ({ data, zone, fullWidth, onClick, buttonOnly }:{ data:any, zone:string, fullWidth?:boolean, index?:number, onClick?:any, buttonOnly?:boolean }) => {
    const theme = useTheme();

    return buttonOnly ? (
            <Button
                sx={{
                    mt: 2,
                    borderRadius: 3,
                    textTransform: 'none',
                }}
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => {
                    onClick ? onClick() :
                        handleClick( data.link, data.zone, data.uid, data?.whatsapp, data?.whatsappMessage, data?.whatsappNumber )
                }}
            >
                { data.buttonText }
            </Button>
            ) : (
            <Card
                sx={{
                    borderRadius: fullWidth ? 0 : 4,
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)'
                }}
                elevation={ 6 }
            >
                <CardActionArea onClick={() => {
                    onClick ? onClick() :
                        handleClick( data.link, data.zone, data.uid, data?.whatsapp, data?.whatsappMessage, data?.whatsappNumber )
                }}>
                    {
                        data?.coverImg?.url && (
                            <CardMedia  sx={{ m: 0, height: fullWidth ? 120 : 'auto' }} component="img" image={ data.coverImg?.url }/>
                        )
                    }
                    {
                        !fullWidth && (
                            <CardContent>
                                <Typography variant={ data.title.length > 26 ? 'caption' : 'h6' }>
                                    { truncateString(  data.title, 100) }
                                </Typography>
                                <Typography variant="body2">
                                    { data.description }
                                </Typography>
                                <Box display="flex" justifyContent="center">
                                    <Button
                                        sx={{
                                            mt: 2,
                                            textTransform: 'none',
                                        }}
                                        color="primary"
                                        variant="contained"
                                        fullWidth
                                    >
                                        { data.buttonText }
                                    </Button>
                                </Box>
                            </CardContent>
                        )
                    }
                </CardActionArea>
            </Card>
            )


}

export default CardComponent;